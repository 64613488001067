

































import Vue from 'vue'

export default Vue.extend({
  name: 'Setting',
  data() {
    return {
      categories: [
        {
          name: 'Taxes',
          description: 'Manage how you charge taxes',
          to: '/settings/taxes',
          icon: 'currency-usd',
        },
        {
          name: 'Markets',
          description: 'Manage products in different countries',
          to: '/settings/markets',
          icon: 'web',
        },
        {
          name: 'Shipping',
          description: 'Get people their shit',
          to: '/settings/shipping',
          icon: 'truck',
        },
      ],
    }
  },
})
